/**
 * ExploreGatewayFooter.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Aug 08, 2021
 * Updated  : Aug 08, 2021
 */

div.ExploreGatewayFooter {
    padding: 35px 0px 35px 0px;
}
