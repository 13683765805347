/**
 * LandingPageGateway.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Jul 02, 2020
 * Updated  : Aug 17, 2021
 */

.ui.vertical.center.aligned.segment.LandingPageGatewayOuterContainer {
  height: 100vh;
  background-size: cover !important;
  background-position: center;
}

.ui.vertical.segment.LandingPageGatewayBackgroundStyle {
  background-image: url('./assets/background_CCF-min.jpg');
}

.ui.container.LandingPageGatewayCoreContentContainer {
  height: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.ui.container.LandingPageGatewayCoreContentContainer h1.header {
  font-size: 36px;
  color: #F8F8FF;
  text-shadow: 0 0 6px #000;
}

.ui.container.LandingPageGatewayCoreContentContainer h2.header {
  text-shadow: 0 0 6px #000;
}

@media (max-width: 640px) {
  .ui.vertical.segment.LandingPageGatewayBackgroundStyle {
    background-image: url('./assets/background_CCF_mobile-min.jpg');
  }
}
