/**
 * ContentPageSharedStyles.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Jul 06, 2020
 * Updated  : Aug 23, 2021
 */

/** ----------------------------- CONTAINER -------------------------------- **/

div.ContentPageSkeletonContentContainerDimension {
  margin: 0px auto;
  max-width: 1600px;
}

/** ------------------------- CONTAINER AUXILLARY -------------------------- **/

div.CenteredBlowoutBox {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

div.InlineBlock {
  display: inline-block;
}

div.OverflowHidden {
  overflow: hidden;
}

div.FloatLeft {
  float: left;
}

div.FloatRight {
  float: right;
}

div.ClearFloat {
  clear: left;
}

.Centered {
  margin: 0 auto;
}

div.VerticalCushionPadding {
  padding: 20px 0;
}

.VerticalCushionPadding {
  padding: 20px 0;
}

.VerticalCushionPaddingTopLarge {
  padding-top: 50px;
}

div.HorizontalCusionPadding {
  padding: 0 50px;
}

div.HorizontalCusionPaddingLeft {
  padding-left: 40px;
}

div.HorizontalCusionPaddingRight {
  padding-right: 40px;
}

div.LeftMargin50px {
  margin-left: 50px;
}

div.RightMargin50px {
  margin-right: 50px;
}

div.BottomMargin50px {
  margin-bottom: 50px;
}

div.BottomMargin20px {
  margin-bottom: 20px;
}

.HalfWidth {
  width: 50%;
}

.FullWidth {
  max-width: 100%;
  height: auto;
}

.DisplayBlock {
  display: block;
}

img.RetainAspectRatio {
  object-fit: contain;
  max-width: 100%;
  width: auto;
  height: auto;
}

/** ------------------------------- TEXT ----------------------------------- **/

p.ContentPageHeadAndSectionIntroText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 1.7;
  word-wrap: break-word;
}

.ContentPageRegularTextSize {
  font-size: 19px;
}

.ContentPageRegularTextLineHeight {
  line-height: 2.0;
}

p.ContentPageCaptionTextSize {
  font-size: 16px;
}

span.TaxonomyBinomialName {
  font-style: italic;
}

.TextCentered {
  text-align: center;
}

.IllustrationFontName {
  font-family: Arial, Helvetica, sans-serif;
}

.IllustrationTitleFontSize {
  font-size: 28px;
}

.IllustrationSubtitleFontSize {
  font-size: 22px;
}

.TextTransformUppercase {
  text-transform: uppercase;
}
