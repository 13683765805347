/**
 * ExploreGatewaySharedStyles.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Aug 08, 2021
 * Updated  : Aug 08, 2021
 */

div.ExploreGatewayBackground {
    background-color: rgba(239, 239, 239, 1.0);
}
