/**
 * ExploreGatewayGrid.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Aug 08, 2021
 * Updated  : Aug 08, 2021
 */

.ExploreGatewayGrid {
    margin: 40px 0px 40px 0px;
}
