/**
 * ExploreGatewayIntro.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Jul 02, 2020
 * Updated  : Aug 22, 2021
 */

/* div.ExploreGatewayIntro {
} */

p.JoinTheRace {
  font-size: 24px;
  font-weight: bold;
  margin: auto;
}

span.SafeTheCheetah {
  font-size: 24px;
  font-weight: bold;
  color: rgb(255,182,31);
}

span.SafeTheCheetah:hover {
  font-size: 24px;
  font-weight: bold;
  color: rgb(255,149,0);
}

div.ExploreGatewayIntroCenterGrid {
  margin: 20px auto;
}

div.ExploreGatewayIntroCenterGridLhsColumnStyle {
  display: block;
  float: right;
  max-width: 500px;
  padding-right: 20px;
  text-align: right;
}

div.ExploreGatewayIntroCenterGridRhsColumnStyle {
  max-width: 480px;
}

p.CCFMissionStatementQuote {
  display: inline;
  padding: 0.25rem 0;

  background-image: linear-gradient(45deg, rgba(255,193,7,0.75) 0%, rgba(255,193,7,0.75) 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.25rem;
  background-position: 0 100%;

  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.6;
  text-align: right;
}

img.ExploreGatewayIntroCCFCheetahMuseumImage {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

p.ExploreGatewayMenuIconLinkText {
  text-align: center;
}

/* a.ExploreGatewayMenuIconLink {
} */
