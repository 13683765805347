/**
 * ExploreGatewayGridItem.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Jul 02, 2020
 * Updated  : Aug 14, 2020
 */

div.ExploreGatewayGridItemInnerContainer {
  display: inline-block;
}

a.ExploreGatewayGridItemImageAnchor:hover {
  filter: opacity(60%);
  -webkit-filter: opacity(60%);
}

img.ui.medium.image.ExploreGatewayGridItemImage {
  width: 232px;
}

img.ui.medium.image.ExploreGatewayGridItemImage:hover {
  filter: opacity(60%);
  -webkit-filter: opacity(60%);
}

h2.ui.left.aligned.header.ExploreGatewayGridItemHeader {
  margin: 0 auto;
}
