/**
 * GlobalPageStyles.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Jul 04, 2020
 * Updated  : Aug 06, 2020
 */

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;  
}

@media only screen and (min-width: 768px) {
  .ui.container {
    width: 700px;
    max-width: 700px;
  }

  nav.ui.container .ui.header {
    float: left;
    margin-top: 12px;
  }

  nav.ui.container .ui.menu {
    float: right;
  }
}
