/**
 * SharedStyles.css
 * Chewbaaka
 *
 * Author   : Tomiko
 * Created  : Aug 08, 2021
 * Updated  : Aug 08, 2021
 */

/** All styles that apply globally goes here ...
 */

*{
    scroll-behavior: smooth !important;
}
